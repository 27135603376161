import React from 'react';
import Layout from '../utils/layout';
import SignUp from '../components/scenes/Auth/scenes/SignUp/SignUp';
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';
const condition = (authUser) => !!authUser;

const SignUpPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(SignUp);

export default () => (
  <Layout hideNav>
    <SignUpPage />
  </Layout>
);
