import React, { Component } from 'react';
import { withFirebase } from '../../../../../../utils/Firebase';
import { LESSONS_PLAN } from '../../../../../../constants/routes';
import { updateUserProfile } from '../../../../Auth/molecules/signInHelpers';
import { navigate } from 'gatsby';
import Input from '../../../../../atoms/Input';
import Button from '../../../../../atoms/Button';

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
    Обліковий запис із такою адресою електронної пошти вже існує.
    Спробуйте увійти із цією адресою. Якщо цю адресу вже використовує 
    одна із соціальних мереж, увійдіть із їх допомогою. А потім прив'яжіть потрібні облікові записи у вашому особистому кабінеті.
  `;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const { email, passwordOne } = this.state;

    try {
      const googleUser = await this.props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne);
      await updateUserProfile(googleUser, this.props.firebase);
      await navigate(LESSONS_PLAN);
    }
    catch (error) {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <div>
        <Input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          autocomplete="name"
          required={true}
          labelName="Ваше ім'я"
        />

        <Input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          autocomplete="email"
          required={true}
          labelName="Електронна пошта"
        />

        <Input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          autocomplete="new-password"
          required={true}
          labelName="Пароль"
        />

        <Input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          autocomplete="repeat-password"
          required={true}
          labelName="Підтвердіть пароль"
          className="input--no-margin"
        />

        <Button
          type="submit"
          disabled={isInvalid}
          onClick={(event) => this.onSubmit(event)}
          text="Зареєструватися"
        />

        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

export default withFirebase(SignUpFormBase);
